<template>
  <v-app>
    <InitializationDialog v-if="isLoading || authenticated === null"></InitializationDialog>
    <div v-else-if="authenticated === false">
      <LoginDialog :show="true"></LoginDialog>
    </div>
    <div v-else>
      <Nav />

      <v-main>
        <router-view class="ma-5"/>
      </v-main>

      <Footer />
    </div>
  </v-app>
</template>

<script>
import Nav from "@/components/Nav"
import Footer from "@/components/Footer"
import EventBus from "@/EventBus"
import {mapState} from "vuex";

import '@/sass/app.scss'
import InitializationDialog from "@/components/InitializationDialog";
import LoginDialog from "@/components/LoginDialog";
import axios from "axios";
import store from "@/store";

export default {
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapState(['authenticated'])
  },
  methods: {
    refreshToken() {
      axios.post(process.env.VUE_APP_AUTH_API + '/refresh-token', {
        token: localStorage.getItem('token')
      })
      .then((response) => {
        if('token' in response.data) {
          localStorage.setItem('token', response.data.token);
        }

        if(localStorage.getItem('remember_me') === '1') {
          setTimeout(() => { this.refreshToken(); }, 60 * 60 * 1000);
        }
      })
      .catch(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('remember_me');
        store.commit('setAuthenticated', false);
      });
    }
  },
  mounted() {
    EventBus.$on("i18n-load-start", () => (this.isLoading = true))
    EventBus.$on("i18n-load-complete", () => (this.isLoading = false))

    if(localStorage.getItem('remember_me') === '1') {
      setTimeout(() => { this.refreshToken(); }, 60 * 60 * 1000);
    }
  },
  components: {
    InitializationDialog,
    LoginDialog,
    Nav,
    Footer
  }
}
</script>

<style>
</style>
