<template>
    <div>
        <v-tabs v-model="tab" align-with-title fixed-tabs>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="myTab in tabs" :key="myTab">
                {{ $t(myTab) }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <!-- alap adatok -->
            <v-tab-item key="munka.alap_adatok">
                <v-card flat>
                    <v-card-text>
                        <v-simple-table class="alapAdatok">
                            <template v-slot:default>
                                <tbody>
                                    <tr v-if="munka.munkaszam">
                                        <td><b>{{ $t('munkaszam') }}</b></td>
                                        <td>{{ munka.munkaszam }}</td>
                                    </tr>
                                    <tr v-if="munka.ve_mennyiseg">
                                        <td><b>{{ $t('munka.vart_eredmeny') }}</b></td>
                                        <td>{{ munka.ve_mennyiseg }} {{ munka.ve_mertekegyseg }}</td>
                                    </tr>
                                    <tr v-if="munka.tema">
                                        <td><b>{{ $t('munka.tema') }}</b></td>
                                        <td>{{ munka.tema.megnevezes }}</td>
                                    </tr>
                                    <tr v-if="munka.kormanyzati_funkcio">
                                        <td><b>{{ $t('munka.kormanyzati_funkcio') }}</b></td>
                                        <td>{{ munka.kormanyzati_funkcio.megnevezes }}</td>
                                    </tr>
                                    <tr v-if="munka.alapitookirat_funkcio">
                                        <td><b>{{ $t('munka.alapitookirat_funkcio') }}</b></td>
                                        <td>{{ munka.alapitookirat_funkcio.megnevezes }}</td>
                                    </tr>
                                    <tr v-if="munka.operativ_sz_e">
                                        <td><b>{{ $t('munka.operativ_sz_e') }}</b></td>
                                        <td>{{ munka.operativ_sz_e.megnevezes }}</td>
                                    </tr>
                                    <tr v-if="munka.szakmai_sz_e">
                                        <td><b>{{ $t('munka.szakmai_sz_e') }}</b></td>
                                        <td>{{ munka.szakmai_sz_e.megnevezes }}</td>
                                    </tr>
                                    <tr v-if="munka.projektvezeto_id">
                                        <td><b>{{ $t('munka.projektvezeto') }}</b></td>
                                        <td>{{ munka.projektvezeto.name }}</td>
                                    </tr>
                                    <tr v-if="munkatarslista.length">
                                        <td><b>{{ $t('munka.munkatarsak') }}</b></td>
                                        <td>
                                            <div v-for="(szemely, index) in munkatarslista" :key="`szemely-${index}`">
                                                {{ szemely.name }} <span v-if="szemely.feladatok.length">({{ szemely.feladatok.join(', ')}})</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                    </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- feladatok -->
            <v-tab-item key="munka.feladatok">
                <v-card flat>
                    <v-card-text>

                        <v-data-table :headers="feladatok_headers" :items="munka.feladat" :items-per-page="10"
                            class="elevation-1" :footer-props="{
                                itemsPerPageAllText: $t('datatable.itemsPerPageAllText'),
                                itemsPerPageText: $t('datatable.itemsPerPageText'),
                                pageText: $t('datatable.pageText')
                            }">

                            <template v-slot:item.munkatars="{ item }">
                                <v-chip label v-for="szemely in item.munkatars" :key="getKey(item.id, szemely.id)"
                                    class="ma-1">
                                    {{ szemely.name }}
                                </v-chip>
                            </template>

                        </v-data-table>

                    </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- pénzügyek -->
            <v-tab-item key="munka.penzugyek">
                <v-card flat>
                    <v-card-text>
                        <!-- források -->
                        <div v-if="Array.isArray(munka.forras) && munka.forras.length" class="mb-5">
                            <h3 class="mb-3">{{ $t('munka.forrasok') }}</h3>

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>{{ $t('megnevezes') }}</th>
                                            <th>{{ $t('munka.forras_kategoria_txt') }}</th>
                                            <th>{{ $t('megjegyzes') }}</th>
                                            <th>{{ $t('munka.brutto') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="forras in munka.forras"
                                            :key="getKey('forras_' + munka.id, forras.id)">
                                            <td>{{ forras.megnevezes }}</td>
                                            <td>{{ forras.forras_kategoria_txt }}</td>
                                            <td>{{ forras.megjegyzes }}</td>
                                            <td>{{ parseFloat(forras.pivot.brutto).toLocaleString('hu',
                                                    { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            }} Ft.</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>

                        <!-- költségek -->
                        <div v-if="Array.isArray(munka.koltseg) && munka.koltseg.length">
                            <h3 class="mb-3">{{ $t('munka.koltsegek') }}</h3>

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>{{ $t('megnevezes') }}</th>
                                            <th>{{ $t('munka.era_kod') }}</th>
                                            <th>{{ $t('megjegyzes') }}</th>
                                            <th>{{ $t('munka.brutto') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="koltseg in munka.koltseg"
                                            :key="getKey('koltseg_' + munka.id, koltseg.id)">
                                            <td>{{ koltseg.megnevezes }}</td>
                                            <td>{{ koltseg.era_kod }}</td>
                                            <td>{{ koltseg.megjegyzes }}</td>
                                            <td>{{ parseFloat(koltseg.pivot.brutto).toLocaleString('hu',
                                                    { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                            }} Ft.</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>

                    </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- naptár -->
            <v-tab-item key="naptar">
                <v-card flat>
                    <v-card-text v-if="calendarTab == 'calendar'">

                        <v-sheet
                            tile
                            height="64"
                            >


                            <v-toolbar
                                flat
                            >
                                <v-btn
                                    outlined
                                    class="mr-4"
                                    color="grey darken-2"
                                    @click="setToday"
                                >
                                    {{ $t('mai_nap') }}
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="prev"
                                >
                                    <v-icon small>
                                    mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="next"
                                >
                                    <v-icon small>
                                    mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                    {{ $refs.calendar.title }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-menu
                                    bottom
                                    right
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            outlined
                                            color="grey darken-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <span>{{ calendar.typeToLabel[calendar.type] }}</span>
                                            <v-icon right>
                                                mdi-menu-down
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click="calendar.type = 'day'">
                                            <v-list-item-title>{{ $t('naptar.nap') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="calendar.type = 'week'">
                                            <v-list-item-title>{{ $t('naptar.het') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="calendar.type = 'month'">
                                            <v-list-item-title>{{ $t('naptar.honap') }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-toolbar>

                        </v-sheet>
                        <v-sheet height="600">

                            <v-calendar ref="calendar"
                                v-model="calendar.focus"
                                :weekdays="calendar.weekdays"
                                :type="calendar.type"
                                :events="calendar.events"
                                :event-overlap-mode="calendar.mode"
                                :event-overlap-threshold="30"
                                :event-color="getEventColor"
                                @click:event="showEvent"
                                @click:more="viewDay"
                                @click:date="viewDay"
                                @change="updateRange"
                            ></v-calendar>

                            <v-menu
                                v-model="calendar.selectedOpen"
                                :close-on-content-click="false"
                                :activator="calendar.selectedElement"
                                offset-x
                            >
                                <v-card
                                    color="grey lighten-4"
                                    min-width="350px"
                                    flat
                                >
                                    <v-toolbar
                                    :color="calendar.selectedEvent.color"
                                    dark
                                    >
                                        <v-toolbar-title v-html="calendar.selectedEvent.name"></v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn icon>
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <span v-html="calendar.selectedEvent.details"></span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            color="secondary"
                                            @click="calendar.selectedOpen = false"
                                        >
                                        {{ $t('bezaras') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>

                        </v-sheet>

                    </v-card-text>

                    <v-card-text v-if="calendarTab == 'gantt'">

                        <frappe-gantt
                            :view-mode="gantt.mode"
                            :tasks="gant_tasks"
                        />

                    </v-card-text>

                </v-card>
            </v-tab-item>

        </v-tabs-items>
    </div>
</template>

<script>
import FrappeGantt from './frappe-gantt.vue';
// import uuidv4 from 'uuid/v4';

export default {
    components: {
        FrappeGantt
    },
    data() {
        return {
            tab: null,
            calendarTab: 'gantt',
            tabs: [
                'munka.alap_adatok', 'munka.feladatok', 'munka.penzugyek', 'munka.naptar'
            ],
            feladatok_headers: [
                {
                    text: this.$t('feladat_kategoria_txt'),
                    align: 'start',
                    value: 'feladat_kategoria_txt',
                    width: '40%'
                },
                { text: this.$t('reszfeladat'), value: 'reszfeladat', sortable: false, width: '20%' },
                { text: this.$t('hatarido'), value: 'hatarido', width: '10%' },
                { text: this.$t('munkatars'), value: 'munkatars', sortable: false, width: '30%' },
            ],
            calendar: {
                weekdays: [1, 2, 3, 4, 5, 6, 0],
                mode: 'stack',
                type: 'month',
                events: [],
                focus: '',
                colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green'],
                names: ['Megbeszélés', 'Koncert', 'Oktatás', 'Felvétel', 'Előadás'],
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                typeToLabel: {
                    month: 'Hónap',
                    week: 'Hét',
                    day: 'nap'
                },
            },
            gantt: {
                mode: 'month',
                /*
                tasks: [
                    {
                        id: '1',
                        name: 'Hello',
                        start: '2019-01-01',
                        end: '2019-01-05',
                        progress: 10,
                    },
                    {
                        id: '2',
                        name: 'World',
                        start: '2019-01-05',
                        end: '2019-01-10',
                        progress: 20,
                        dependencies: '1'
                    },
                    {
                        id: '3',
                        name: 'From',
                        start: '2019-01-10',
                        end: '2019-01-15',
                        progress: 30,
                        dependencies: '2'
                    },
                    {
                        id: '4',
                        name: 'Lloyd',
                        start: '2019-01-15',
                        end: '2019-01-20',
                        progress: 40,
                        dependencies: '3'
                    }
                ],
                */
                debugEventLog: []
            }
        }
    },
    props: ['munka'],
    mounted () {
      // this.$refs.calendar.checkChange()
    },
    computed: {
        gant_tasks () {
            let tasks = [];
            this.munka.feladat.forEach(function (feladat) {
                if(feladat.kezdes && feladat.vege) {
                    tasks.push({
                        id: feladat.id,
                        name: feladat.feladat_kategoria_txt,
                        start: feladat.kezdes,
                        end: feladat.vege
                    })
                }
            });
            return tasks
        },
        munkatarslista () {
            let munkatarsak = [];

            this.munka.feladat.forEach(function (feladat) {
                feladat.munkatars.forEach(function (szemely) {
                    let feladat_kategoria = feladat.feladat_kategoria_txt.substring(0, feladat.feladat_kategoria_txt.indexOf(' /')).toLowerCase();
                    if (munkatarsak.filter(e => e.name === szemely.name).length == 0) {
                        munkatarsak.push({name: szemely.name, feladatok: []});
                    }
                    munkatarsak.forEach(function(munkatars) {
                        if (munkatars.name === szemely.name && feladat_kategoria) {
                            if (!munkatars.feladatok.includes(feladat_kategoria)) munkatars.feladatok.push(feladat_kategoria);
                        }
                    });
                });
            });

            munkatarsak = munkatarsak.sort(function(a,b){
                let x = a.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, ""),
                    y = b.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                if (x>y) {return 1;}
                if (x<y) {return -1;}
                return 0;
            });

            return munkatarsak
        },
    },
    methods: {
        // calendar related methods
        getKey(id1, id2) {
            return id1 + '-' + id2
        },
        setToday () {
            this.calendar.focus = ''
        },
        viewDay ({ date }) {
            this.calendar.focus = date
            this.calendar.type = 'day'
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        updateRange ({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)
            const days = (max.getTime() - min.getTime()) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0
                const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                const second = new Date(first.getTime() + secondTimestamp)

                events.push({
                    name: this.calendar.names[this.rnd(0, this.calendar.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.calendar.colors[this.rnd(0, this.calendar.colors.length - 1)],
                    timed: !allDay,
                })
            }

            this.calendar.events = events
        },
        getEventColor(event) {
            return event.color
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.calendar.selectedEvent = event
                this.calendar.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.calendar.selectedOpen = true))
            }

            if (this.calendar.selectedOpen) {
                this.calendar.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
    }
}
</script>
<style scoped>
.alapAdatok>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    width: 320px !important;
}
.alapAdatok>.v-data-table__wrapper>table>tbody>tr>td {
    padding: 5px;
}

</style>