<template>
    <v-dialog v-model="dialog" width="70%" v-if="item !== null" style='z-index:5001;' scrollable @input="whenClosed()"
        :fullscreen="$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on }">
            <div v-on="on" class="max2lines" :title="item.megnevezes">{{ item.megnevezes }}</div>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                {{ item.megnevezes }}
            </v-card-title>

            <v-card-text class="pt-3">
                <work-details :munka="item"></work-details>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <div class="flex-grow-1"></div>
                <LocalizedLink :to="munka_reszletek_url(item.id)" color="secondary" class="mr-3">
                    <v-btn >
                        {{ $t('reszletek') }}
                    </v-btn>
                </LocalizedLink>
                <v-btn color="primary" @click="dialog = false; whenClosed()">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>
  
<script>
import { ES_INDEX } from '@/constants/elastic'
import WorkDetails from "@/components/WorkDetails"
import LocalizedLink from "@/components/LocalizedLink"
import axios from 'axios'

export default {
    data() {
        return {
            dialog: false,
            should_cycle: true,
            item: null
        }
    },
    components: {
        WorkDetails,
        LocalizedLink
    },
    computed: {
    },
    methods: {
        whenClosed() {
        },
        mailto(email) {
            return 'mailto:' + email;
        },
        munka_reszletek_url(id) {
            return '/work/' + id;
        }
    },
    mounted() {
        if (this.itemObject) {
            this.item = this.itemObject
        } else if (this.id && this.type) {
            console.log('fetching from ES')
            /*
            const query = {
              query: {
                match: {
                  'id': this.id
                }
              }
            }
            */

            let query = {
                query: {
                    match: {
                        id: this.id,
                    },
                },
            };

            if (isNaN(this.id)) {
                query = {
                    query: {
                        match: {
                            item_id: this.id,
                        },
                    },
                };
            }
            // console.log(query)

            if (typeof (ES_INDEX[this.type][this.$i18n.locale]) !== 'undefined') {
                const esIndex = ES_INDEX[this.type][this.$i18n.locale]
                axios.get(process.env.VUE_APP_ELASTIC_ENDPOINT + '/' + esIndex + '/_search', {
                    params: {
                        source: JSON.stringify(query),
                        source_content_type: 'application/json'
                    },
                    auth: {
                        username: process.env.VUE_APP_ES_AUTH_USERNAME,
                        password: process.env.VUE_APP_ES_AUTH_PASSWORD
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        if (typeof (res.data.hits.total) !== 'undefined' && res.data.hits.total.value === 1) {
                            this.item = res.data.hits.hits[0]._source
                        }
                    }
                })
            }
        }
    },
    name: 'WorkDialog',
    props: ['itemObject', 'lang', 'width', 'id', 'type', 'inlineTextLink', 'text']
}
</script>
  
<style>
.yt_thumbnail :hover {
    cursor: pointer;
}

.v-card__title .v-image,
.v-card__title .max2lines {
    cursor: pointer;
}
</style>
  
  
  