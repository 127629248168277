<template>
  <div>
    <v-app-bar
      app
      v-if="isMobile"
      class="mobileHeader"
    >
          <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>

          <v-toolbar-title style="padding-left: 0"><a href="/" style="text-decoration: none;" class="appname ml-3 mr-5"><span v-html="$t('app.title')"></span></a></v-toolbar-title>
    </v-app-bar>

    <v-app-bar
      app
      v-else
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <div class="ppMenu">
          <span class="menuItem">
            <a href="/" style="text-decoration: none;">
              <v-img
                  src="/img/HH_logo_50px.png"
                  height="48"
                  width="50"
              ></v-img>
            </a>
          </span>
          <template v-for="item in items">
            <span class="menuItem textMenuItem" :key="item.url">
              <LocalizedLink :to="item.url">{{ $t(item.text) }}</LocalizedLink>
            </span>
          </template>
          <v-btn class="logout-btn" color="primary" @click="logout">
            <v-icon left dark>mdi-logout</v-icon>
            {{ $t('logout') }}
          </v-btn>
        </div>
      </v-toolbar-title>
    </v-app-bar>


    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
        <div class="align-center">
          <v-list
            dense
          >
            <template v-for="(item, i) in items">
              <v-list-item 
                v-if="isExternal(item.url)" 
                :key="i"
              >
                <a :href="item.url[$i18n.locale]" target="_blank">
                <v-list-item-content>
                  <v-list-item-title class="text-white">
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
                </a>
              </v-list-item>

              <v-list-item
                v-else
                :key="i"
              >
                  <LocalizedLink :to="item.url">
                    <v-list-item-content>
                      <v-list-item-title class="text-white">
                        {{ $t(item.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </LocalizedLink>
              </v-list-item>
            </template>

          </v-list>
        </div>

    </v-navigation-drawer>

  </div>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink"
import store from "@/store";

export default {
  data: () => ({
    drawer: false,
    items: [
      { text: 'nav.persons', url: 'persons' },
      { text: 'nav.works', url: 'works' }
      /*
      { text: 'nav.about', url: 'about' },
      { text: 'nav.about', url: 'page', params: {hu: {slug: 'rolunk'}, en: {slug: 'about'}} }
      */
    ]
  }),
  methods: {
    jumpToLang (langcode) {
      window.location.href = langcode
    },
    isExternal (url) {
      return typeof url.hu !== 'undefined'
    },
    logout () {
      localStorage.removeItem('token');
      localStorage.removeItem('remember_me');
      store.commit('setAuthenticated', false);
    }
  },
  computed: {
    isMobile () {
      var ret = false
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          ret = true; break
        case 'sm':
          ret = true; break
      }
      return ret
    },
  },
  components: { 
    LocalizedLink 
  }
}
</script>

<style scoped>
.ppMenu {
  display: flex;
  align-items: center;
}
.ppMenu .menuItem {
  height: 48px;
  line-height: 48px;
}
.ppMenu .textMenuItem {
  padding-left: 20px;
}
.ppMenu .textMenuItem a {
  text-decoration: none;
}
.mobileHeader a.appname {
  font-size: 20px;
  color: white !important;
  font-weight: 700;
}
.v-app-bar.v-app-bar--fixed {
  z-index: 1000;
}
.v-navigation-drawer {
  z-index: 1001;
}

.v-toolbar__title {
  flex: 1;
}

.logout-btn {
  margin-left: auto;
}
</style>
