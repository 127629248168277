<template>
  <v-dialog :value="true" persistent max-width="500" content-class="initialization-dialog">
    <v-progress-linear indeterminate color="primary" rounded></v-progress-linear>
  </v-dialog>
</template>
<script>

export default {
  data() {
    return {};
  }
}
</script>
