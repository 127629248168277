import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Root from "./Root"
import i18n, { loadLocaleMessagesAsync } from "@/i18n"
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"
import axios from "axios";
import store from "../store/index.js";

Vue.use(VueRouter)

const { locale } = i18n

const routes = [
  {
    path: "/",
    redirect: locale
  },
  {
    path: "/:locale",
    component: Root,
    children: [
      {
        path: "",
        name: "home",
        component: Home
      },
      {
        path: "page/:slug",
        name: "page",
        component: () =>
          import(/* webpackChunkName: "page" */ "../views/Page.vue")
      },
      {
        path: "persons",
        name: "persons",
        component: () =>
          import(/* webpackChunkName: "persons" */ "../views/Persons.vue")
      },
      {
        path: "works",
        name: "works",
        component: () =>
          import(/* webpackChunkName: "works" */ "../views/Works.vue")
      },
      {
        path: 'work/:id',
        name: 'work',
        component: () =>
          import(/* webpackChunkName: "work" */ "../views/Work.vue")
      },
      {
        path: "about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.params.locale !== from.params.locale) {
    const { locale } = to.params

    loadLocaleMessagesAsync(locale).then(() => {
      setDocumentLang(locale)

      setDocumentDirectionPerLocale(locale)

      setDocumentTitle(i18n.t("app.title"))
    })
  }

  // Verify token / login state
  axios.get(process.env.VUE_APP_AUTH_API + '/me', {
    params: {
      token: localStorage.getItem('token')
    }
  }).then((res) => {
    store.commit('setAuthenticated', res.status !== 401);
  }).catch(() => {
    store.commit('setAuthenticated', false);
  }).finally(() => {
    next();
  });
})

export default router
