<template>
  <v-dialog
        v-model="dialog"
        width="70%"
        v-if="item !== null"
        style='z-index:5001;'
        scrollable
        @input="whenClosed()"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on }">
          <v-img
            :src="item.avatar_thumb"
            :aspect-ratio="1/1"
            :width="width"
            v-on="on"
            v-if="item.avatar"
          ></v-img>
          <v-img
            :src="photocard_missing"
            :aspect-ratio="1/1"
            :width="width"
            v-on="on"
            v-else
          ></v-img>
        </template>       

        <v-card >
          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            {{ item.name }}
         </v-card-title>

          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="6">
                <v-img
                  :src="item.avatar"
                  :aspect-ratio="1/1"
                  :width="width"
                  v-if="item.avatar"
                ></v-img>
                <v-img
                  :src="photocard_missing"
                  :aspect-ratio="1/1"
                  :width="width"
                  v-else
                ></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <h3>{{ $t('informacio') }}</h3>
                <div><b>{{ $t('munkakor') }}</b>: {{ item.munkakor }}</div>
                <div><b>{{ $t('szervezeti_egyseg') }}</b>: {{ item.szervezeti_egyseg }}</div>
                <div v-if="item.email"><b>{{ $t('email') }}</b>: <a :href="mailto(item.email)">{{ item.email }}</a></div>
                <div v-if="item.hh_telefonszam"><b>{{ $t('telefonszam') }}</b>: {{ item.hh_telefonszam }}</div>
                <div v-if="item.hh_helyiseg"><b>{{ $t('helyiseg') }}</b>: {{ item.hh_helyiseg }}</div>
                <div v-if="item.hh_mellek"><b>{{ $t('mellek') }}</b>: {{ item.hh_mellek }}</div>
                <div v-if="item.hh_megjegyzes"><b>{{ $t('megjegyzes') }}</b>: {{ item.hh_megjegyzes }}</div>

                <!-- munkak -->
                <div v-if="item.munkak" class="mt-3">
                  <h3>{{ $t('munkak') }}</h3>

                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="munka in item.munkak"
                      :key="munka.munka.munkaszam"
                    >
                      <v-expansion-panel-header>
                        <span class="ma-1">
                          <v-badge
                            color="secondary"
                            :content="munka.feladatok.length"
                            offset-y="12"
                            offset-x="-5"
                          >
                          {{ munka.munka.megnevezes }}
                          </v-badge>
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-for="feladat in munka.feladatok" :key="feladat.id">
                          - {{ feladat.feladat_kategoria_txt }} <span v-if="feladat.reszfeladat">({{ feladat.reszfeladat }})</span>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>

              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="primary"
              @click="dialog = false; whenClosed()"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
</template>

<script>
  import { ES_INDEX } from '@/constants/elastic'
  import axios from 'axios'

  export default {
    data () {
      return {
        dialog: false,
        should_cycle: true,
        item: null
      }
    },
    computed: {
      photocard_missing () {
        if (this.$i18n.locale === 'en') {
          return 'https://via.placeholder.com/600x421?text=The+photo+is+not+available'
        }
        return 'https://via.placeholder.com/600x421?text=A+fotó+nem+érhető+el'
      }
    },
    methods: {
      whenClosed () {
      },
      mailto (email) {
        return 'mailto:' + email;
      }
    },
    mounted () {
      if (this.itemObject) {
        this.item = this.itemObject
      } else if (this.id && this.type) {
        console.log('fetching from ES')
        /*
        const query = {
          query: {
            match: {
              'id': this.id
            }
          }
        }
        */

        let query = {
          query: {
            match: {
              id: this.id,
            },
          },
        };

        if (isNaN(this.id)) {
          query = {
            query: {
              match: {
                item_id: this.id,
              },
            },
          };
        }
        // console.log(query)

        if (typeof (ES_INDEX[this.type][this.$i18n.locale]) !== 'undefined') {
          const esIndex = ES_INDEX[this.type][this.$i18n.locale]
          axios.get(process.env.VUE_APP_ELASTIC_ENDPOINT + '/' + esIndex + '/_search', {
            params: {
              source: JSON.stringify(query),
              source_content_type: 'application/json'
            },
            auth: {
              username: process.env.VUE_APP_ES_AUTH_USERNAME,
              password: process.env.VUE_APP_ES_AUTH_PASSWORD
            }
          }).then((res) => {
            if (res.status === 200) {
              if (typeof (res.data.hits.total) !== 'undefined' && res.data.hits.total.value === 1) {
                this.item = res.data.hits.hits[0]._source
              }
            }
          })
        }
      }
    },
    name: 'PersonDialog',
    props: ['itemObject', 'lang', 'width', 'id', 'type', 'inlineTextLink', 'text']
  }
</script>

<style>
.yt_thumbnail :hover {
  cursor: pointer;
}
.v-card__title .v-image, .v-card__title .max2lines {
  cursor: pointer;
}
</style>


