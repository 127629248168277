// based on:
// https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65

import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

// apollo + graphql
// https://hasura.io/learn/graphql/vue/apollo-client/
import VueApollo from "vue-apollo";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

// imports
import ReactiveSearch from '@appbaseio/reactivesearch-vue'
import PersonDialog from '@/components/PersonDialog'
import WorkDialog from '@/components/WorkDialog'

Vue.config.productionTip = false

Vue.component('person-dialog', PersonDialog)
Vue.component('work-dialog', WorkDialog)

// use plugins
Vue.use(ReactiveSearch)

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

// apollo setup
Vue.use(VueApollo);

const getHeaders = () => {
  const headers = {};
   const token = window.localStorage.getItem('apollo-token');
   if (token) {
     headers.authorization = `Bearer ${token}`;
   }
   return headers;
};

// Create an http link:
const link = new HttpLink({
   uri: process.env.VUE_APP_GQL_API_ENDPOINT,
   fetch,
   headers: getHeaders()
});

const client = new ApolloClient({
   link: link,
   cache: new InMemoryCache({
     addTypename: true
   })
});

const apolloProvider = new VueApollo({
  defaultClient: client,
})

// custom mixins
Vue.mixin({
  methods: {
    i18nsort (data) {
      // console.log(data)

      data.sort(function(a, b) {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
        let keyA = a.key.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, ""),
          keyB = b.key.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
        // Compare the 2 values
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      return data
    },
    elasticEndpoint () {
      return process.env.VUE_APP_ELASTIC_ENDPOINT;
    }
  }
})


new Vue({
  i18n,
  vuetify,
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
