<template>
  <div>
    <v-container>
      <!--
      <page :page="page" :lang="this.$i18n.locale" v-if="page"> </page>
      -->
      <h1 class="mb-5">Nyitólap</h1>

      <v-btn><LocalizedLink to="persons">Munkatársak lista oldal</LocalizedLink></v-btn>

      <p class="mt-5">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et eros ut urna porttitor eleifend. Sed maximus eget nibh sed convallis. Ut tincidunt, dolor et ornare lacinia, metus massa luctus diam, eu porttitor quam sapien quis diam. Sed venenatis tellus diam, nec ultricies metus accumsan id. Sed nec imperdiet enim. Sed tortor lorem, scelerisque sed hendrerit non, hendrerit sed mi. Sed ac nunc sit amet dolor tincidunt tempor a eget metus. Aliquam dignissim vestibulum sollicitudin. Morbi placerat dictum enim, vitae auctor libero volutpat at. Proin a tristique libero. Sed quam felis, ultrices a porta maximus, luctus vel arcu.
      </p>
    </v-container>
  </div>
</template>

<script>
// import Page from "@/components/Page";
// import { PAGE_QUERY } from "@/constants/graphql";
import LocalizedLink from "@/components/LocalizedLink"

export default {
  name: "Home",
  data() {
    return {
      page: null,
      lang: this.$i18n.locale
    };
  },
  components: {
    LocalizedLink,
    // Page
  },
  /*
  apollo: {
    page: {
      query: PAGE_QUERY,
      variables() {
        return {
          slug: "home",
          lang: this.$i18n.locale,
        };
      },
    },
  },
  */
};
</script>

<style>
  .v-btn a {
    text-decoration: none;
  }
</style>

