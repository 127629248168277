import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    woa_list_view: false,
    authenticated: null
  },
  mutations: {
    setWoaListView (state, value) {
      state.woa_list_view = value
    },
    setAuthenticated (state, value) {
      state.authenticated = value;
    }
  },
  modules: {}
});
