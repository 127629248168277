<template>
  <div>
    <v-footer
      app
      :padless="true"
      absolute
    >
      <v-card
        flat
        tile
        width="100%"
        class="grey lighten-5 text-center"
      >
        <v-divider></v-divider>

        <v-card-text class="">
          &copy; {{ new Date().getFullYear() }} — <span v-html="$t('footer_text')"></span>
        </v-card-text>
      </v-card>        
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
  })
}
</script>


<style scoped>
.partner a {
  width: 100%;
}
</style>