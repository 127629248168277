<template>
  <v-dialog v-model="show" max-width="480px" persistent>
    <v-card>
      <v-card-text>

        <img src="/img/HH_logo.png" class="login-logo" />
        <v-alert type="error" icon="mdi-alert-octagon" v-if="errorMessage !== ''">
          {{ errorMessage }}
        </v-alert>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :label="$t('login.email')"
            prepend-icon="mdi-account"
            required
            :rules="[rules.filled, rules.email]"
            @keydown.enter="login"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :label="$t('login.password')"
            prepend-icon="mdi-lock"
            type="password"
            required
            :rules="[rules.filled]"
            @keydown.enter="login"
          ></v-text-field>

          <v-checkbox
            v-model="rememberMe"
            :label="$t('login.remember_me')"
            :value="true"
          ></v-checkbox>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary ma-5" @click="login" :loading="loginInProgress">{{ $t('login.btn_text') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios
    from "axios";

  export default {
    props: ["show"],
    data() {
      return {
        valid: true,
        loginInProgress: false,
        email: '',
        password: '',
        rememberMe: true,
        errorMessage: '',
        rules: {
          filled: value => {
            return value.length > 0 || this.$t('login.validation.filled', this.$i18n.locale);
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$t('login.validation.email', this.$i18n.locale)
          }
        }
      };
    },
    computed: {

    },
    methods: {
      login() {
        this.loginInProgress = true;
        this.errorMessage = '';
        axios.post(process.env.VUE_APP_AUTH_API + '/login', {
          login: this.email,
          password: this.password
        })
        .then((response) => {
          if('token' in response.data) {
            if(this.rememberMe === true) {
              localStorage.setItem('remember_me', '1');
            }
            else {
              localStorage.removeItem('remember_me');
            }
            localStorage.setItem('token', response.data.token);
            location.reload();
          }
          else {
            this.errorMessage = this.$t('login.general_error');
            this.loginInProgress = false;
          }
        })
        .catch((e) => {
          if(e.toString().includes('422') || e.toString().includes('401')) {
            this.errorMessage = this.$t('login.wrong_username_or_password');
          }
          else {
            this.errorMessage = this.$t('login.general_error');
          }

          this.loginInProgress = false;
        });
      }
    }
  }
</script>

<style>
.v-overlay--active {
  z-index: 1101 !important;
}

.v-dialog__content--active {
  z-index: 1102 !important;
}

.login-logo {
  width: 150px;
  margin-left: calc(50% - 75px);
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
